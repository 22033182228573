@import '../../assets/styles/variables';

.dashboard {
  .ant-alert-warning {
    background-color: $white;
    margin-bottom: 8px;

    .alert-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .add-floor {
    position: relative;

    &:not(:first-child):before {
      content: '';
      width: 96%;
      height: 5px;
      background-color: $white;
      position: absolute;
      top: -5px;
      right: 0;
    }

    @media (max-width: 576px) {
      &:not(:first-child)::before {
        width: 88%;
      }
    }

    .ant-col-1 {
      text-align: center;

      .anticon {
        font-size: 36px;
        cursor: pointer;
      }
    }
  }
}
