@import './assets/styles/variables.scss';

body {
  margin: 0;
  font-family: sans-serif, 'Roboto', 'Helvetica Neue', Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  color: $white;

  .ant-typography,
  .ant-result-title,
  .ant-result-subtitle,
  .ant-form-item-label label {
    color: $white;
  }

  .ant-modal-body .ant-form-item-label label {
    color: $black;
  }
}

.user-form-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  form {
    width: 100%;
    max-width: 300px;
    padding: 16px 16px 0 16px;
    background-color: rgba($black, 0.5);
    border-radius: 10px;
    margin-bottom: 8px;
    text-align: center;

    > a {
      display: block;
      text-align: center;
      padding-bottom: 8px;
    }

    .ant-form-item-label label {
      color: $white;
    }

    button {
      display: block;
      margin: 0 auto;
    }
  }

  &__error.ant-typography {
    color: $red;
  }
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card-wrapper {
  background-color: lighten($bg-color, 20%);
}

.settings-table {
  table {
    tr {
      td,
      th {
        text-align: center;
      }
    }
  }

  &-btns {
    button:first-child {
      margin-right: 15px;
    }
  }
}
