@import '../../../../assets/styles/variables';

.room-view {
  .ant-modal-body {
    padding: 0 24px 24px;

    .ant-typography {
      color: $black;
    }
  }

  &__control {
    padding-bottom: 1em;

    .ant-btn:first-of-type {
      margin-right: 10px;
    }
  }

  .ant-modal-footer {
    .ant-btn:first-child {
      display: none;
    }
  }

  &__chart {
    canvas {
      height: 300px !important;
      width: 100% !important;
    }

    margin-bottom: 20px;
  }
}
