.gateways-page {
  .gateways-actions {
    margin-bottom: 10px;
  }

  .gateways-table-actions {
    display: flex;

    button {
      margin-left: 10px;
    }
  }

  .ant-table-content {
    overflow-x: scroll;
  }
}
