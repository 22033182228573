@import '../../assets/styles/variables.scss';

.floor {
  position: relative;

  .add-room {
    > div {
      width: 60px;
      height: 60px;
      border: 4px solid $white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        cursor: pointer;
        font-size: 36px;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:not(:first-child)::before {
    content: '';
    width: 96%;
    height: 5px;
    background-color: $white;
    position: absolute;
    top: -5px;
    right: 0;
  }

  @media (max-width: 576px) {
    &:not(:first-child)::before {
      width: 88%;
    }
  }

  &:first-child {
    .floor-header {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &:last-child {
    .floor-header {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .ant-col {
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      display: inline-block;
      margin: 5px 10px;
    }
  }

  &-header {
    background-color: $white;
    display: flex !important;
    text-align: center;

    > div {
      color: $black !important;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      margin: 0 auto !important;
      padding: 10px 0;
    }
  }

  &-rooms {
    padding: 5px;
  }
}

.dashboard.editing {
  .floor-header {
    cursor: pointer;
  }
}
