.settings-page {
  .ant-form-item {
    margin: 0;
  }

  .ant-col {
    margin-bottom: 10px;
  }

  .settings-buttons {
    .ant-form-item {
      .ant-col {
        flex-direction: row-reverse;

        @media screen and (max-width: 500px) {
          flex-direction: column;
        }

        .ant-btn:last-child {
          margin-left: 10px;
        }
      }
    }

    @media screen and (max-width: 500px) {
      margin-top: 20px;
    }
  }

  .settings-reg-buttons {
    .ant-col {
      .ant-btn:last-child {
        margin-left: 10px;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        margin-top: 20px;

        .ant-btn {
          width: 100%;
          margin-bottom: 10px;

          &:last-child {
            margin-left: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .code-input {
    @media screen and (max-width: 500px) {
      .ant-col {
        width: 100%;
      }
    }
  }
}
