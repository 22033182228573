@import '../../assets/styles/variables';

.site-layout {
  background-color: $bg-color !important;
  width: 100%;
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;

  .site-header {
    background-color: $bg-color;
    padding: 0;
    margin-bottom: 30px;
    height: auto;
    line-height: 1.4;
    text-align: center;

    &-row {
      border-bottom: 4px solid $white;
      min-height: 60px;
    }
  }

  .ant-typography {
    color: $white;
  }
}
