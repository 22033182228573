@import '../../assets/styles/variables';

.room {
  vertical-align: middle;
  text-align: center;
  color: $white;

  &-status {
    cursor: pointer;
    border: 4px solid $white;
    border-radius: 5px;
    background-color: rgba($white, 0.2);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon-user {
      display: none;
    }

    .anticon-disconnect {
      display: block;
      color: $white;
      font-size: 36px;
    }

    &.occupied,
    &.dont-disturb,
    &.makeup {
      .anticon-user {
        display: block;
        color: $white;
        font-size: 36px;
      }
    }

    &.makeup {
      border-color: $green;
    }

    &.dont-disturb {
      border-color: $red;
    }
  }
}
