@import '../../assets/styles/variables';

.account-settings {
  .ant-col {
    color: $white;
  }

  &__row {
    margin-bottom: 10px;

    .email-error,
    .pass-error {
      border-color: $red;
      box-shadow: 0 0 0 2px rgba($red, 0.5);
    }

    @media screen and (max-width: 600px) {
      .ant-col:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .ant-divider {
    border-top: 2px solid $white;
  }
}
