@import '../../assets/styles/variables';

.site-header-nav {
  width: 100%;
  background-color: lighten($bg-color, 20%) !important;
  text-align: center !important;
  padding: 0 !important;

  .ant-dropdown-menu-item {
    padding: 10px 40px;
    border-bottom: 1px solid $white;

    &:last-child {
      border: none;
    }

    a {
      color: $white;
    }

    &:hover {
      a {
        color: $black;
      }
    }
  }
}

.site-header {
  .anticon {
    cursor: pointer;
    font-size: 36px;
    color: $white;
  }

  .ant-col-12 {
    display: flex;

    &:last-child {
      flex-direction: row-reverse;
    }
  }
}

.logo {
  display: block;

  &-img {
    background-image: url('../../assets/images/logo_light_symphony.png');
    width: 200px;
    height: 55px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;

    @media (max-width: 400px) {
      width: 120px;
      height: 30px;
    }
  }
}
